import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    user: any;
    isAuthenticated: boolean;
    constructor(public authSrv: AuthService, private element: ElementRef, private router: Router) {
    }

    ngOnInit() {
      this.authSrv.isLogged.subscribe(isAuthenticated => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          this.user = JSON.parse(localStorage.getItem('user'));
        }
      });
      // console.log(this.user);
    }

    logout() {
      this.authSrv.logout();
    }

    goLogin() {
      this.router.navigate(['/login']);
    }

}
