// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  patient_collection: 'patients2',
  firebase: {
    apiKey: 'AIzaSyCXSeky21xeB_HhvQmemLRfQzZ-xpS3UWw',
    authDomain: 'miracle-clinical-research.firebaseapp.com',
    databaseURL: 'https://miracle-clinical-research-default-rtdb.firebaseio.com',
    projectId: 'miracle-clinical-research',
    storageBucket: 'miracle-clinical-research.appspot.com',
    messagingSenderId: '781489181393',
    appId: '1:781489181393:web:927ad7f6050ddebfeb2bf0',
    measurementId: 'G-8DSZ687D5F'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
