import { ListComponent } from './patient/list/list.component';
import { CreateComponent } from './patient/create/create.component';
import { VerifyEmailComponent } from './admin/verify-email/verify-email.component';
import { ForgotPasswordComponent } from './admin/forgot-password/forgot-password.component';
import { LoginComponent } from './admin/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule  } from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'create', component: CreateComponent, canActivate: [AuthGuard]},
  {path: 'list', component: ListComponent, canActivate: [AuthGuard]},
  {path: 'forgot', component: ForgotPasswordComponent},
  {path: 'verify', component: VerifyEmailComponent},
  {path: '', redirectTo: '/list', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
