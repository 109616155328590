import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CreateComponent } from '../create/create.component';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { environment } from 'src/environments/environment';
// import * as data from './import.JSON';

export interface Patient {
  id?: string;
  name?: string;
  lastname?: string;
  dob?: string;
  sex?: string;
  phone?: string;
  conditions?: Array<string>;
}


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements AfterViewInit {
  patient: Patient = {};
  patients$: Observable<any[]>;
  user: any;
  // source: any = (data as any).default;
  dataSource: MatTableDataSource<Patient>;
  displayedColumns: string[] = ['name', 'lastname', 'phone', 'sex', 'dob', 'conditions'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  resultsLength = 0;

  constructor(
    public firestore: AngularFirestore,
    public dialog: MatDialog) {
      this.patients$ = firestore.collection(environment.patient_collection, ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.orderBy('name');
        return query;
      }).valueChanges({ idField: 'id' });
  }

  ngAfterViewInit() {
    this.patients$.subscribe(rows => {
      // console.log(rows);
      this.resultsLength = rows.length;
      this.dataSource = new MatTableDataSource(rows);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // async importPatients() {
  //   console.log(this.source);
  //   for (const row of this.source) {
  //     await this.firestore.collection('patients').add(row);
  //   }

  // }

  newPatient(): void {
    const dialogRef = this.dialog.open(CreateComponent, {
      width: '50%',
      data: {title: 'Create Patient', row: this.patient}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.patient = result;
    });
  }

  editPatient(patient: any) {
    console.log(patient);
    const dialogRef = this.dialog.open(CreateComponent, {
      width: '50%',
      data: {title: 'Update Patient', row: patient}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.patient = result;
    });
  }

  openSearch() {

  }

}
