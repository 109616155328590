import { environment } from 'src/environments/environment';
import { ListComponent } from './../list/list.component';
import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Patient } from '../list/list.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'underscore';


export interface DialogData {
  title: string;
  row: Patient;
}

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements AfterViewInit {

  @Input() error: string | null;

  form: FormGroup;
  private itemDoc: AngularFirestoreDocument<Patient>;

  conditions$: Observable<any[]>;
  genders: Array<any> = [{code: 'F', label: 'Female'}, {code: 'M', label: 'Male'}];

  patient: Patient = {};

  constructor(
    public firestore: AngularFirestore,
    public dialogRef: MatDialogRef<ListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ) {
    this.conditions$ = firestore.collection('conditions').valueChanges();
    if (this.data.row && this.data.row.id) {
      this.patient = this.data.row;
      // console.log(`patients2/${this.patient.id}`);
      this.itemDoc = this.firestore.collection(environment.patient_collection).doc<any>(this.patient.id);
    }
    // console.log(this.data.row);
    this.form = new FormGroup({
      name: new FormControl(this.patient.name, Validators.required),
      lastname: new FormControl(this.patient.lastname, Validators.required),
      sex: new FormControl(this.patient.sex,  Validators.required),
      dob: new FormControl({value: new Date(this.patient.dob), disabled: true},
      [Validators.required, Validators.pattern('^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$')]),
      phone: new FormControl(this.patient.phone, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      conditions: new FormControl(this.patient.conditions, Validators.required),
    });
   }

   ngAfterViewInit() {
    // console.log(this.data.row);
    // if (this.data.row)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.valid) {
      if (this.patient.id) {
        this.itemDoc.update(this.form.value).then(result => {
          console.log('After Update', result);
          this.dialogRef.close(result);
        }, err => {
          this.error = err.message;
        });
      } else {
        this.firestore.collection(environment.patient_collection).add(this.form.value).then(result => {
          console.log('After Create', result);
          this.dialogRef.close(result);
        }, err => {
          this.error = err.message;
        });
      }
    }
  }

}
