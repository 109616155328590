import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() error: string | null;

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private  authService: AuthService, public  router: Router) { }

  submit() {
    if (this.form.valid) {
      this.authService.login(this.form.value.email, this.form.value.password).then(result => {
        console.log(result);
        this.router.navigate(['list']);
      }, err => {
        console.log(err.message);
        this.error = err.message;
      });
    }
  }

  ngOnInit() {
    // this.authService.logout();
    this.authService.isLogged.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.router.navigate(['list']);
      }
    });
  }

}

